import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/axiosConfig';
import './EstablishmentVisits.css';
import hideIcon from '../../assets/hide.png';
import viewIcon from '../../assets/show.png';
import Tooltip from '@mui/material/Tooltip';

const EstablishmentRate = ({ establishmentId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isRateVisible, setIsRateVisible] = useState(true);
  const [currentRate, setCurrentRate] = useState(0);
  const [newRate, setNewRate] = useState('');

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get(`/api/establishments/${establishmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedRate = response.data.visitRate || 0;
        setCurrentRate(fetchedRate);
        setNewRate(fetchedRate.toString());
      } catch (error) {
        console.error('Error al obtener la tarifa:', error);
        alert('No se pudo cargar la tarifa actual.');
      }
    };

    fetchRate();
  }, [establishmentId]);

  const handleToggleVisibility = () => {
    setIsRateVisible(!isRateVisible);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Reemplazar comas y asegurar que el nuevo valor sea un número
    const cleanedNewRate = parseFloat(newRate.replace(/,/g, ''));

    if (isNaN(cleanedNewRate) || cleanedNewRate <= 0) {
      alert('Por favor, ingresa un valor válido para la tarifa.');
      return;
    }

    if (cleanedNewRate !== currentRate) {
      const confirmed = window.confirm(`¿Estás seguro que quieres cambiar la tarifa de $${formatCurrency(currentRate)} a $${formatCurrency(cleanedNewRate)}?`);
      if (confirmed) {
        const updateRate = async () => {
          try {
            const token = localStorage.getItem('authToken');
            await api.put(`/api/establishments/${establishmentId}`, { visitRate: cleanedNewRate }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            setCurrentRate(cleanedNewRate); // Actualizamos el estado de la tarifa actual
            setIsEditing(false);
            alert('Tarifa actualizada exitosamente.');
          } catch (error) {
            console.error('Error al actualizar la tarifa:', error);
            alert('No se pudo actualizar la tarifa.');
          }
        };

        updateRate();
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleRateChange = (e) => {
    let input = e.target.value.replace(/,/g, '');

    // Restringe a números y un máximo de 9 caracteres
    if (/^\d*$/.test(input)) {
      if (input.length > 9) {
        return;
      }
      // Si se borra todo, vuelve a '0'
      if (input === '') {
        setNewRate('0');
      } else {
        // Actualiza el estado formateando el número
        setNewRate(formatCurrency(input));
      }
    }
  };

  const handleRateFocus = () => {
    // Si el valor es "0", lo dejamos vacío para facilitar la edición
    if (newRate === '0') {
      setNewRate('');
    }
  };

  const handleRateBlur = () => {
    // Si el valor queda vacío, se convierte de nuevo a "0"
    if (newRate === '') {
      setNewRate('0');
    }
  };

  const formatCurrency = (value) => {
    return parseInt(value, 10).toLocaleString('en-US');
  };

  const isRateDefined = currentRate !== 0 && currentRate !== null;

  return (
    <div className="rate-container">
      <h2 data-testid="rate-title">Tarifa por Visita</h2>
      <p>En esta sección podrás ver y actualizar la tarifa por visita del establecimiento.</p>

      <h3 data-testid="current-rate-title">Tarifa Actual</h3>
      {!isEditing ? (
        <div className='amount-container'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span data-testid="current-rate"
              style={{
                fontSize: '24px',
                marginRight: '8px',
                fontWeight: isRateDefined ? 'bold' : 'normal',
              }}
            >
              {isRateDefined
                ? `$${isRateVisible ? formatCurrency(currentRate) : '*****'}`
                : 'Monto de cobro por visita no generado'}
            </span>
            {isRateDefined && (
              <Tooltip title={isRateVisible ? 'Ocultar tarifa actual' : 'Mostrar tarifa actual'} arrow>
                <img
                  data-testid="toggle-rate-visibility"
                  src={isRateVisible ? hideIcon : viewIcon}
                  alt="Toggle Tarifa"
                  style={{ marginLeft: '10px', cursor: 'pointer', width: '24px' }}
                  onClick={handleToggleVisibility}
                />
              </Tooltip>
            )}
          </div>
          <button
            data-testid="update-rate-button"
            className="update-rate-button"
            onClick={handleEditClick}
            style={{
              marginLeft: '10px',
              backgroundColor: '#4CAF50',
              color: 'white',
              borderRadius: '4px',
              padding: '5px 10px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Actualizar Tarifa
          </button>
        </div>
      ) : (
        <div className='amount-container'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
              $
              <input
                data-testid="new-rate-input"
                type="text"
                value={newRate}
                onChange={handleRateChange}
                onFocus={handleRateFocus}
                onBlur={handleRateBlur}
                style={{
                  marginLeft: '10px',
                  fontSize: '20px',
                  padding: '5px',
                  marginRight: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  textAlign: 'center',
                  marginBottom: '1rem',
                }}
              />
            </span>
          </div>
          <button
            data-testid="save-rate-button"
            className="save-rate-button"
            onClick={handleSaveClick}
            style={{
              marginLeft: '10px',
              backgroundColor: '#4CAF50',
              color: 'white',
              borderRadius: '4px',
              padding: '5px 10px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Guardar Nueva Tarifa
          </button>
        </div>
      )}
    </div>
  );
};

EstablishmentRate.propTypes = {
  establishmentId: PropTypes.string.isRequired,
};

export default EstablishmentRate;