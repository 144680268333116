import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import api from '../../api/axiosConfig';

const CategoryPieChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/establishments/categories/all', {
          headers: { Authorization: `Bearer ${token}` }
        });

        const categories = response.data.map(category => category.industry);  // Obtenemos el nombre del rubro
        const counts = response.data.map(category => category.count);  // Obtenemos el conteo de establecimientos por rubro

        setChartData({
          labels: categories,
          datasets: [{
            data: counts,
            backgroundColor: [
              '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
              '#FF9F40', '#FFCD56', '#4CAF50', '#FF5722', '#8E44AD'
            ], // Colores para cada rubro
            hoverBackgroundColor: [
              '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
              '#FF9F40', '#FFCD56', '#4CAF50', '#FF5722', '#8E44AD'
            ]
          }]
        });
      } catch (error) {
        console.error('Error al obtener los rubros:', error);
      }
    };

    fetchAllCategories();
  }, []);

  const options = {
    plugins: {
      title: {
        display: true,
        text: '% total por rubro',
        font: {
          size: 18
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((sum, current) => sum + current, 0);
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return `${label}: ${percentage}`;
          }
        }
      }
    }
  };

  return (
    <div data-testid="category-pie-chart">
      {chartData ? (
        <Pie data={chartData} options={options} data-testid="pie-chart" />
      ) : (
        <p data-testid="loading-text">Cargando gráfico...</p>
      )}    </div>
  );
};

export default CategoryPieChart;