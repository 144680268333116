import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/axiosConfig';
import PeriodicVisitsModal from './PeriodicVisitsModal';
import './VisitsInfoSection.css';

const VisitsInfoSection = ({ establishmentId }) => {
  const [nextVisit, setNextVisit] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchNextVisit(); // Aseguramos que los datos se actualicen inmediatamente después de cerrar el modal
  };

  const fetchNextVisit = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${establishmentId}/nextVisit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.nextVisit) {
        setNextVisit(response.data.nextVisit);
        setDaysLeft(response.data.daysLeft);
      } else {
        setNextVisit(null);
        setDaysLeft(null);
      }
    } catch (error) {
      console.error('Error al obtener la próxima visita:', error);
    }
  };

  useEffect(() => {
    fetchNextVisit();
  }, [establishmentId]);

  // Función para ajustar la fecha y formatearla a "día mes año"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="visits-info-section">
      <button className="periodic-visit-button" onClick={openModal} data-testid="open-periodic-visit-modal-button">
        Configurar Visitas Periódicas
      </button>

      <PeriodicVisitsModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        establishmentId={establishmentId}
        refreshNextVisit={fetchNextVisit}
        data-testid="periodic-visits-modal"
      />

      {nextVisit ? (
        <div className="next-visit-info" data-testid="next-visit-info">
          <h3>Próxima Visita</h3>
          <p><strong>Fecha:</strong> {formatDate(nextVisit)}</p>
          <div className="days-remaining">
            <p>Días hasta la próxima visita:</p>
            <div className="days-box" data-testid="days-left-box">{daysLeft}</div>
          </div>
        </div>
      ) : (
        <p data-testid="no-visit-scheduled-message">No hay visitas programadas para este establecimiento.</p>)}
    </div>
  );
};

VisitsInfoSection.propTypes = {
  establishmentId: PropTypes.string.isRequired,
};

export default VisitsInfoSection;