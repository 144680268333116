import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api/axiosConfig';
import './VerifyEmail.css';

const VerifyEmail = () => {
  const [code, setCode] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [showResendInput, setShowResendInput] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/verify-email', { email, code });

      if (response.status === 200) {
        toast.success('¡Correo verificado exitosamente!', { position: 'top-right', autoClose: 3000 });
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else if (response.status === 201) {
        toast.warn('El código de verificación expiró. Se ha enviado uno nuevo a tu correo. Por favor ingrésalo.', { position: 'top-right', autoClose: 5000 });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Código incorrecto o expirado. Inténtalo de nuevo.', {
        position: 'top-right',
        autoClose: 4000,
      });
    }
  };

  const handleShowResendInput = () => {
    setShowResendInput(true);
  };

  const handleResendSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/resend-verification-code', { email: emailInput });

      const message = response.data.message;
      if (message.includes('No hay ningún usuario registrado')) {
        toast.warning('No hay ningún usuario registrado con este correo. Por favor, regístrate primero.', { position: 'top-right', autoClose: 5000 });
      } else if (message.includes('El usuario ya está verificado')) {
        toast.warning('El usuario ya está verificado. Inicia sesión.', { position: 'top-right', autoClose: 5000 });
      } else if (message.includes('Se ha enviado uno nuevo a tu correo.')) {
        toast.warn('El código de verificación expiró. Se ha enviado uno nuevo a tu correo. Por favor ingrésalo.', { position: 'top-right', autoClose: 5000 });
      } else {
        toast.success('Se ha reenviado el código de verificación a tu correo.', { position: 'top-right', autoClose: 5000 });
      }
    } catch (error) {
      toast.error('Error al reenviar el código. Verifica el correo ingresado.', { position: 'top-right', autoClose: 5000 });
    }
  };

  return (
    <div className="verify-email-container">
      <div className="verify-email-card">
        <h2>Verificar Email</h2>
        <p>Ingresa el código que hemos enviado a tu correo: <strong>{email}</strong></p>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Código de Verificación</label>
            <input
              data-testid="input-verification-code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              maxLength="6"
              className="code-input"
              placeholder="Ingresa el código de 6 dígitos"
            />
          </div>
          <button data-testid="btn-verify-email" type="submit">Verificar</button>
        </form>
        <button data-testid="btn-resend-code" className="resend-button" onClick={handleShowResendInput}>
          Reenviar Código
        </button>
        {showResendInput && (
          <form onSubmit={handleResendSubmit} className="resend-form">
            <label>Correo Electrónico</label>
            <input
              data-testid="input-resend-email"
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              required
              placeholder="Ingresa tu correo electrónico"
            />
            <button data-testid="btn-send-resend-email" type="submit">Enviar</button>
          </form>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default VerifyEmail;