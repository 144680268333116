// PDFViewModal.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './PDFViewModal.css';
import api from '../../api/axiosConfig';

const PDFViewModal = ({ form, onClose }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(userData.companyLogo || null);
        setSignatureImage(userData.signatureImage || null);
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');

    let logoBase64 = null;
    let signatureBase64 = null;

    try {
      if (companyLogo) {
        logoBase64 = await loadImageAsBase64(companyLogo);
      }
      if (signatureImage) {
        signatureBase64 = await loadImageAsBase64(signatureImage);
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }

    if (logoBase64) {
      try {
        const img = new Image();
        img.src = logoBase64;

        img.onload = () => {
          const maxWidth = 99.2;
          const maxHeight = 27.7;
          const aspectRatio = img.width / img.height;

          let finalWidth = maxWidth;
          let finalHeight = maxHeight;

          if (aspectRatio > 1) {
            finalHeight = Math.min(maxHeight, maxWidth / aspectRatio);
          } else {
            finalWidth = Math.min(maxWidth, maxHeight * aspectRatio);
          }

          // Verificar si el tamaño es válido
          if (!isNaN(finalWidth) && !isNaN(finalHeight) && finalWidth > 0 && finalHeight > 0) {
            doc.addImage(logoBase64, 'PNG', 10, 10, finalWidth, finalHeight);
          } else {
            console.error('Invalid image dimensions:', finalWidth, finalHeight);
          }

          // Agregar el resto del contenido al PDF
          doc.setFontSize(18);
          doc.text(form.title, 10, 50);
          doc.setFontSize(12);
          doc.text(form.description || 'Sin descripción', 10, 60);

          const tableData = form.fields.map((field) => [field.label || field.name, field.value]);
          doc.autoTable({
            head: [['Campo', 'Valor']],
            body: tableData,
            startY: 70,
          });

          const finalY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 20 : 100;

          if (signatureBase64) {
            doc.addImage(signatureBase64, 'PNG', 140, finalY, 50, 30);
            doc.setFontSize(12);
            doc.text(`Nombre: ${userName}`, 140, finalY + 35);
            doc.text(`Número de matrícula: ${licenseNumber}`, 140, finalY + 45);
          } else {
            doc.setFontSize(12);
            doc.text(`Nombre: ${userName}`, 10, finalY + 20);
            doc.text(`Número de matrícula: ${licenseNumber}`, 10, finalY + 30);
          }

          const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');
          doc.save(`${form.title} ${creationDate}.pdf`);
        };
      } catch (error) {
        console.error('Error handling image dimensions:', error);
      }
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{form.title}</h2>
          <button data-testid="close-modal-button" onClick={onClose}>Cerrar</button>
        </div>
        <div className="modal-body">
          <p>{form.description || 'Sin descripción'}</p>
          <table data-testid="form-fields-table" className="form-fields">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {form.fields.map((field) => (
                <tr key={field._id}>
                  <td>{field.label || field.name}</td>
                  <td>{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button data-testid="download-pdf-button" onClick={handleDownloadPDF}>Descargar PDF</button>
        </div>
      </div>
    </div>
  );
};

PDFViewModal.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.null]),
      })
    ).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PDFViewModal;