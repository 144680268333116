import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import './EstablishmentForm.css';
import api from '../../api/axiosConfig';
import provinces from '../../utils/provinces';

const EstablishmentForm = ({ onSubmit, initialData, isEditing }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    addressNumber: '',
    noAddressNumber: false,
    city: '',
    province: '',
    industry: '',
    contactInfo: '',
    neighborhood: '',
    postalCode: '',
    country: 'Argentina',
  });

  const [localidades, setLocalidades] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [showOtherCity, setShowOtherCity] = useState(false);
  const [errors, setErrors] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  // References for focusing on inputs
  const nameRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const provinceRef = useRef(null);
  const industryRef = useRef(null);
  const contactInfoRef = useRef(null);


  const fetchRubros = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/rubros', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (Array.isArray(response.data)) {
        setRubros(response.data);
      } else {
        console.error('La respuesta de rubros no es un array:', response.data);
      }
    } catch (error) {
      console.error('Error al obtener los rubros:', error);
    }
  }, []);

  const fetchLocalidades = useCallback(async (province) => {
    try {
      const response = await fetch(
        `https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&campos=id,nombre&max=5000`
      );
      const data = await response.json();
      if (data && data.localidades) {
        const sortedLocalidades = data.localidades
          .map((loc) => loc.nombre)
          .sort((a, b) => a.localeCompare(b));
        setLocalidades(sortedLocalidades);
      } else {
        setLocalidades([]);
      }
    } catch (error) {
      console.error('Error al obtener las localidades:', error);
      setLocalidades([]);
    }
  }, []);

  useEffect(() => {
    fetchRubros();
    if (initialData?.province) {
      fetchLocalidades(initialData.province);
    }
  }, [fetchRubros, fetchLocalidades, initialData]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name || '',
        address: initialData.address || '',
        addressNumber: initialData.addressNumber || '',
        noAddressNumber: initialData.noAddressNumber || false,
        city: initialData.city || '',
        province: initialData.province || '',
        industry: initialData.industry || '',
        contactInfo: initialData.contactInfo || '',
        neighborhood: initialData.neighborhood || '',
        postalCode: initialData.postalCode || '',
        country: initialData.country || 'Argentina',
      });

      // Verificar si la localidad está en la lista y mostrar el campo "Otra Localidad" si es necesario
      if (initialData.province) {
        fetchLocalidades(initialData.province).then(() => {
          if (initialData.city && !localidades.includes(initialData.city)) {
            setShowOtherCity(true);
          }
        });
      }
    }
  }, [initialData, fetchLocalidades]);

  const handleProvinceChange = async (province) => {
    setFormData((prevData) => ({
      ...prevData,
      province,
      city: '',
    }));
    setShowOtherCity(false);
    await fetchLocalidades(province);
    setSuggestions([]);
  };

  const handleCityChange = (city) => {
    setFormData((prevData) => ({
      ...prevData,
      city: city === 'Ingresar manualmente' ? '' : city,
    }));
    setShowOtherCity(city === 'Ingresar manualmente');
    setSuggestions([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchSuggestions = async (query) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}, ${formData.city}, ${formData.province}, Argentina&addressdetails=1`
      );
      const data = await response.json();
      if (data.length === 0) {
        setError('Dirección no encontrada, pruebe cambiando de localidad, provincia o dirección.');
      }
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      setError('Error al buscar la dirección, intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSearch = () => {
    // Validación de provincia, ciudad y dirección antes de buscar
    if (!formData.province || !formData.city || !formData.address) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        province: !formData.province ? 'Selecciona una provincia para continuar.' : '',
        city: !formData.city ? 'Selecciona una localidad para continuar.' : '',
        address: !formData.address ? 'Ingresa una dirección para continuar.' : '',
      }));
      return;
    }

    const query = `${formData.address} ${formData.addressNumber || ''}`.trim();
    if (query) {
      fetchSuggestions(query);
    }
  };

  // establecer las coordenadas del resultado seleccionado.
  const handleAutocompleteSelect = (suggestion) => {
    const { address, lat, lon } = suggestion;
    const street = address.road || formData.address; // Solo el nombre de la calle
    const neighborhood = address.neighbourhood || address.suburb || '';
    const city = address.city || address.town || address.village || formData.city;
    const province = address.state || formData.province;
    const postalCode = address.postcode || '';

    const isCityInList = localidades.includes(city);

    setFormData((prevData) => ({
      ...prevData,
      address: street, // Solo la calle, sin número
      neighborhood,
      city: isCityInList ? city : prevData.city,
      province,
      postalCode,
    }));
    setLatitude(lat); // Guardar latitud
    setLongitude(lon); // Guardar longitud
    setSuggestions([]);

    if (!isCityInList) {
      setShowOtherCity(true);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let focusField = null;
    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = 'El nombre del establecimiento es obligatorio';
      focusField = nameRef;
    } else if (formData.name.length < 3) {
      newErrors.name = 'El nombre debe tener al menos 3 caracteres';
      focusField = nameRef;
    }

    if (!formData.address || formData.address.trim() === '') {
      newErrors.address = 'La dirección es obligatoria';
      focusField = focusField || addressRef;
    } else if (formData.address.length < 5) {
      newErrors.address = 'La dirección debe tener al menos 5 caracteres';
      focusField = focusField || addressRef;
    }

    if (!formData.province || formData.province.trim() === '') {
      newErrors.province = 'La provincia es obligatoria';
      focusField = focusField || provinceRef;
    }

    if (showOtherCity && (!formData.city || formData.city.trim() === '')) {
      newErrors.city = 'Otra Localidad es obligatoria';
      focusField = focusField || cityRef;
    } else if (!showOtherCity && (!formData.city || formData.city.trim() === '')) {
      newErrors.city = 'La localidad es obligatoria';
      focusField = focusField || cityRef;
    }

    if (!formData.industry || formData.industry === '') {
      newErrors.industry = 'El rubro es obligatorio';
      focusField = focusField || industryRef;
    }

    if (!formData.contactInfo || formData.contactInfo.trim() === '') {
      newErrors.contactInfo = 'La información de contacto es obligatoria';
      focusField = focusField || contactInfoRef;
    }

    // Verifica si focusField no es nulo antes de hacer foco.
    if (focusField && focusField.current) {
      focusField.current.focus();
    }

    // Verifica si focusField no es nulo antes de hacer foco.
    if (focusField && focusField.current) {
      focusField.current.focus();
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsSubmitting(true);
      try {
        await onSubmit({
          ...formData,
          latitude,
          longitude,
        });
        setIsSubmitting(false);
      } catch (error) {
        console.error('Error al guardar el establecimiento:', error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <form className="establishment-form" onSubmit={handleSubmit}>
      <div>
        <label>Nombre del establecimiento</label>
        <input
          data-testid="establishment-name"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          ref={nameRef}
          className={errors.name ? 'input-error' : ''}
        />
        {errors.name && <span className="error-text">{errors.name}</span>}
      </div>

      <div>
        <label>País</label>
        <input
          data-testid="country"
          type="text"
          name="country"
          value={formData.country}
          readOnly />
      </div>

      <div>
        <label>Provincia</label>
        <select
          data-testid="province-select"
          name="province"
          value={formData.province}
          onChange={(e) => handleProvinceChange(e.target.value)}
          className={errors.province ? 'input-error' : ''}
        >
          <option value="">Seleccione una provincia</option>
          {provinces.map((province, index) => (
            <option key={index} value={province}>
              {province}
            </option>
          ))}
        </select>
        {errors.province && <span className="error-text">{errors.province}</span>}
      </div>

      <div>
        <label>Localidad</label>
        <select
          data-testid="city-select"
          name="city"
          value={showOtherCity ? '' : formData.city || ''}
          onChange={(e) => handleCityChange(e.target.value)}
          ref={cityRef}
          className={errors.city ? 'input-error' : ''}
        >
          <option value="">Seleccione una localidad</option>
          {localidades.map((localidad, index) => (
            <option key={index} value={localidad}>
              {localidad}
            </option>
          ))}
          <option value="Ingresar manualmente">Ingresar manualmente</option>
        </select>
        {errors.city && <span className="error-text">{errors.city}</span>}
      </div>

      {showOtherCity && (
        <div>
          <label>Otra Localidad</label>
          <input
            data-testid="other-address-input"
            placeholder="Ingrese la localidad manualmente"
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className={errors.city ? 'input-error' : ''}
          />
          {errors.city && <span className="error-text">{errors.city}</span>}
        </div>
      )}

      <div>
        <label>Dirección</label>
        <input
          data-testid="address-input"
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          ref={addressRef}
          className={errors.address ? 'input-error' : ''}
        />
        {errors.address && <span className="error-text">{errors.address}</span>}
      </div>

      <div>
        <label>Número</label>
        <input
          data-testid="number-input"
          type="text"
          name="addressNumber"
          value={formData.noAddressNumber ? '' : formData.addressNumber}
          onChange={handleChange}
          disabled={formData.noAddressNumber}
        />
      </div>

      <button
        data-testid="address-search-button"
        type="button"
        onClick={handleAddressSearch}>
        Buscar dirección
      </button>

      {loading && <div className="loading">Cargando...</div>}
      {error && <div className="error-text">{error}</div>}

      {suggestions.length > 0 && (
        <ul>
          <div className="suggestions-buuble">
            {suggestions.map((suggestion, index) => (
              <li className='suggestion-bubble'
                key={index}
                onClick={() => handleAutocompleteSelect(suggestion)}
              >
                {suggestion.display_name}
              </li>
            ))}
          </div>
        </ul>
      )}

      <div>
        <label>Barrio</label>
        <input
          type="text"
          name="neighborhood"
          value={formData.neighborhood}
          onChange={handleChange}
          readOnly
        />
      </div>

      <div>
        <label>Código Postal</label>
        <input
          type="text"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleChange}
          readOnly
        />
      </div>

      <div>
        <label>Rubro</label>
        <select
          data-testid="industry-select"
          name="industry"
          value={formData.industry || ''}
          onChange={handleChange}
          className={errors.industry ? 'input-error' : ''}
        >
          <option value="">Seleccione un rubro</option>
          {rubros.map((rubro) => (
            <option key={rubro._id} value={rubro._id}>
              {rubro.name}
            </option>
          ))}
        </select>
        {errors.industry && <span className="error-text">{errors.industry}</span>}
      </div>

      <div>
        <label>Información de contacto</label>
        <input
          data-testid="contact-info-input"
          type="text"
          name="contactInfo"
          value={formData.contactInfo}
          onChange={handleChange}
          className={errors.contactInfo ? 'input-error' : ''}
        />
        {errors.contactInfo && <span className="error-text">{errors.contactInfo}</span>}
      </div>

      <button
        data-testid="submit-button"
        type="submit"
        disabled={isSubmitting}>
        {isSubmitting ? 'Guardando...' : isEditing ? 'Actualizar Establecimiento' : 'Crear Establecimiento'}
      </button>
    </form>
  );
};

EstablishmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEditing: PropTypes.bool,
};

EstablishmentForm.defaultProps = {
  initialData: null,
  isEditing: false,
};

export default EstablishmentForm;