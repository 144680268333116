import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Sidebar from '../components/common/Sidebar';
import CalendarView from '../components/calendar/CalendarView';
import CategoryPieChart from '../components/charts/CategoryPieChart';  // Importa el gráfico
import api from '../api/axiosConfig';


const Dashboard = () => {
  const userName = localStorage.getItem('userName');
  const [timeframe, setTimeframe] = useState('week');
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalEstablishments, setTotalEstablishments] = useState(0);
  const [categoryData, setCategoryData] = useState([]);  // Estado para el pie chart

  const fetchTotalVisits = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/visits/total?period=${timeframe}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTotalVisits(response.data.totalVisits);
    } catch (error) {
      console.error('Error al obtener el total de visitas:', error);
    }
  };

  const fetchTotalEstablishments = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments/total', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTotalEstablishments(response.data.totalEstablishments);
    } catch (error) {
      console.error('Error al obtener el total de establecimientos:', error);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments/categories/all', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategoryData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expirado o no válido, redirige al login
        console.error('Token no válido o expirado, redirigiendo al login.');
        localStorage.removeItem('authToken'); // Limpia el token
        window.location.href = '/login'; // Redirige al login
      } else {
        console.error('Error al obtener los datos de categorías:', error);
      }
    }
  };

  useEffect(() => {
    fetchTotalVisits();
  }, [timeframe]);

  useEffect(() => {
    fetchTotalEstablishments();
    fetchCategoryData();  // Llamada para obtener los datos de categorías
  }, []);

  return (
    <div className="dashboard-container" data-testid="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2 className="welcome-message" data-testid="welcome-message">Hola {userName}, bienvenido a tu panel de control</h2>

        <div className="dashboard-main">
          <div className="calendar-section" data-testid="calendar-section">
            <CalendarView onVisitClick={() => { }} />
          </div>


          <div className="info-cards-wrapper">
            <div className="info-cards-section">
              <div className="total-visits-section" data-testid="total-visits-section">
                <h3>Total de Visitas</h3>
                <div className="timeframe-selector" data-testid="timeframe-selector">
                  <label data-testid="timeframe-week">
                    <input
                      type="radio"
                      value="week"
                      checked={timeframe === 'week'}
                      onChange={() => setTimeframe('week')}
                    />
                    Semana
                  </label>
                  <label data-testid="timeframe-month">
                    <input
                      type="radio"
                      value="month"
                      checked={timeframe === 'month'}
                      onChange={() => setTimeframe('month')}
                    />
                    Mes
                  </label>
                  <label data-testid="timeframe-year">
                    <input
                      type="radio"
                      value="year"
                      checked={timeframe === 'year'}
                      onChange={() => setTimeframe('year')}
                    />
                    Año
                  </label>
                </div>
                <div className="total-visits-display" data-testid="total-visits-display">
                  <h1>{totalVisits}</h1>
                  <p>Visitas en {timeframe === 'week' ? 'esta semana' : timeframe === 'month' ? 'este mes' : 'este año'}</p>
                </div>
              </div>

              <div className="total-establishments-section" data-testid="total-establishments-section">
                <h3>Total de Establecimientos</h3>
                <div className="total-visits-display" data-testid="total-establishments-display">
                  <h1>{totalEstablishments}</h1>
                  <p>Establecimientos registrados</p>
                </div>
              </div>
            </div>

            <div className="category-piechart-section" data-testid="category-piechart-section">
              <CategoryPieChart data={categoryData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;