import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import api from '../../api/axiosConfig';
import './PaginatedForm.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme }) => ({
    variants: [
      {
        props: { checked: true },
        style: {
          '.MuiFormControlLabel-label': {
            color: theme.palette.primary.main,
          },
        },
      },
    ],
  })
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  value: PropTypes.any,
};

const PaginatedForm = ({ formJson, establishmentId, onClose, onFormCreated, formToEdit }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [responses, setResponses] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fieldRefs = useRef({});

  const fieldsPerPage = 5;
  const totalPages = formJson && formJson.fields ? Math.ceil(formJson.fields.length / fieldsPerPage) : 0;

  useEffect(() => {
    if (formToEdit && formToEdit.fields) {
      const initialResponses = {};
      formToEdit.fields.forEach((field) => {
        initialResponses[field.name] = field.value || '';
      });
      setResponses(initialResponses);
    }
  }, [formToEdit]);

  const scrollToTop = () => {
    const formFields = document.querySelector('.form-fields');
    if (formFields) {
      formFields.scrollTop = 0;
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const handleInputChange = (name, value, type) => {
    setIsDirty(true);
    if (type !== 'file') {
      setResponses({ ...responses, [name]: value });
      setFieldErrors({ ...fieldErrors, [name]: false });
    }
  };

  const handleCheckboxChange = (name, option) => {
    const currentValues = responses[name] || [];
    const newValues = currentValues.includes(option)
      ? currentValues.filter((val) => val !== option)
      : [...currentValues, option];
    setResponses({ ...responses, [name]: newValues });
  };

  const handleClose = () => {
    if (!isDirty) {
      onClose();
    } else if (window.confirm('Si cierras el formulario, se perderán los cambios no guardados. ¿Deseas continuar?')) {
      onClose();
    }
  };

  const handleSave = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    scrollToTop();

    const missingFields = formJson.fields.filter(field => field.required && !responses[field.name]);
    if (missingFields.length > 0) {
      const errors = {};
      let firstErrorPage = -1;

      missingFields.forEach(field => {
        errors[field.name] = true;
        const fieldIndex = formJson.fields.findIndex(f => f.name === field.name);
        const pageIndex = Math.floor(fieldIndex / fieldsPerPage);
        if (firstErrorPage === -1 || pageIndex < firstErrorPage) {
          firstErrorPage = pageIndex;
        }
      });

      setFieldErrors(errors);
      if (firstErrorPage !== -1) {
        setCurrentPage(firstErrorPage);
        setTimeout(() => {
          const firstErrorField = missingFields[0].name;
          if (fieldRefs.current[firstErrorField]) {
            fieldRefs.current[firstErrorField].scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }

      setIsSubmitting(false);
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const apiUrl = formToEdit
        ? `/api/establishments/${establishmentId}/checklists/${formToEdit._id}`
        : `/api/establishments/${establishmentId}/checklist`;

      const method = formToEdit ? 'put' : 'post';

      const payload = {
        title: formJson.title,
        description: formJson.description || "",
        fields: formJson.fields.map(field => ({
          label: field.label,
          name: field.name,
          type: field.type,
          options: field.options || [],
          value: responses[field.name] !== undefined ? responses[field.name] : (field.required ? '' : null),
        })),
      };

      await api[method](apiUrl, JSON.stringify(payload), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      toast.success('Formulario guardado con éxito', { position: 'bottom-right', autoClose: 3000 });
      onFormCreated();
    } catch (error) {
      console.error('Error al guardar el formulario:', error);
      toast.error('Hubo un problema al guardar el formulario. Por favor, inténtelo de nuevo.', { position: 'bottom-right', autoClose: 3000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFieldOptions = (field) => (
    <div className="options-grid">
      {field.options.map((option, i) => (
        <FormControlLabel
          key={i}
          control={
            field.type === 'checkbox' ? (
              <Checkbox
                checked={(responses[field.name] || []).includes(option)}
                onChange={() => handleCheckboxChange(field.name, option)}
              />
            ) : (
              <Radio
                checked={responses[field.name] === option}
                onChange={() => handleInputChange(field.name, option, 'radio')}
                value={option}
              />
            )
          }
          label={option}
          style={{ backgroundColor: '#b2dbed', borderRadius: '5px' }}
        />
      ))}
    </div>
  );

  if (!formJson || !formJson.fields) {
    return <p>No se puede cargar el formulario. Por favor, inténtalo de nuevo.</p>;
  }

  const startIndex = currentPage * fieldsPerPage;
  const fieldsToDisplay = formJson.fields.slice(startIndex, startIndex + fieldsPerPage);

  return (
    <div className="paginated-form">
      <button data-testid="close-button" className="close-button" onClick={handleClose}>X</button>
      <h2 data-testid="form-title">{formJson.title}</h2>
      <div data-testid="form-fields" className="form-fields">
        {fieldsToDisplay.map((field, index) => (
          <div
            data-testid={`form-group-${field.name}`}
            key={index}
            className={`form-group ${fieldErrors[field.name] ? 'error' : ''}`}
            ref={(el) => (fieldRefs.current[field.name] = el)}
          >
            <label data-testid={`label-${field.name}`}>{field.label}</label>
            {['radio', 'checkbox'].includes(field.type) ? (
              renderFieldOptions(field)
            ) : (
              <input
                data-testid={`input-${field.name}`}
                type={field.type}
                name={field.name}
                value={responses[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value, field.type)}
              />
            )}
            {fieldErrors[field.name] && (
              <span data-testid={`error-text-${field.name}`} className="error-text">Dato mandatorio</span>
            )}
          </div>
        ))}
      </div>
      <div className="pagination-container">
        <button data-testid="first-page-button" onClick={() => handlePageClick(0)} disabled={currentPage === 0}>&laquo;</button>
        <button data-testid="prev-page-button" onClick={handlePreviousPage} disabled={currentPage === 0}>&lt;</button>
        {[...Array(totalPages)].map((_, i) => (
          <button
            data-testid={`page-button-${i}`}
            key={i}
            className={i === currentPage ? 'active' : ''}
            onClick={() => handlePageClick(i)}
          >
            {i + 1}
          </button>
        ))}
        <button data-testid="next-page-button" onClick={handleNextPage} disabled={currentPage === totalPages - 1}>&gt;</button>
        <button data-testid="last-page-button" onClick={() => handlePageClick(totalPages - 1)} disabled={currentPage === totalPages - 1}>&raquo;</button>
      </div>
      <button data-testid="save-button" className="save-button" onClick={handleSave} disabled={isSubmitting}>Guardar</button>
      <ToastContainer />
    </div>
  );
};

PaginatedForm.propTypes = {
  formJson: PropTypes.object.isRequired,
  establishmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  formToEdit: PropTypes.object,
};

export default PaginatedForm;