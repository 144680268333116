import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './FormDetails.css';
import api from '../../api/axiosConfig';

const FormDetails = ({ form, onBack }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(userData.companyLogo || null);
        setSignatureImage(userData.signatureImage || null);
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');

    let logoBase64 = null;
    let signatureBase64 = null;

    try {
      if (companyLogo) {
        logoBase64 = await loadImageAsBase64(companyLogo);
      }
      if (signatureImage) {
        signatureBase64 = await loadImageAsBase64(signatureImage);
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }

    // Renderizar el logo manteniendo la proporción
    if (logoBase64) {
      const img = new Image();
      img.src = logoBase64;
      const maxWidth = 99.2;
      const maxHeight = 27.7;
      const aspectRatio = img.width / img.height;

      let finalWidth = maxWidth;
      let finalHeight = maxHeight;

      // Ajustar el tamaño manteniendo la relación de aspecto sin forzar a que llene el espacio máximo
      if (aspectRatio > 1) {
        finalWidth = maxWidth;
        finalHeight = maxWidth / aspectRatio;
        if (finalHeight > maxHeight) {
          finalHeight = maxHeight;
          finalWidth = maxHeight * aspectRatio;
        }
      } else {
        finalHeight = maxHeight;
        finalWidth = maxHeight * aspectRatio;
        if (finalWidth > maxWidth) {
          finalWidth = maxWidth;
          finalHeight = maxWidth / aspectRatio;
        }
      }

      doc.addImage(logoBase64, 'PNG', 10, 10, finalWidth, finalHeight);
    }

    doc.setFontSize(18);
    doc.text(form.title, 10, logoBase64 ? 50 : 20);

    doc.setFontSize(12);
    doc.text(form.description || 'Sin descripción', 10, logoBase64 ? 60 : 30);

    const tableData = form.fields.map((field) => {
      const label = field._doc?.label || field.label || 'Sin etiqueta';
      const value = field._doc?.value || field.value || 'Sin valor';
      return [label, value];
    });

    doc.autoTable({
      head: [['Campo', 'Valor']],
      body: tableData,
      startY: logoBase64 ? 70 : 40,
    });

    const finalY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 20 : (logoBase64 ? 100 : 50);

    // Renderizar la firma manteniendo la proporción
    if (signatureBase64) {
      const img = new Image();
      img.src = signatureBase64;
      const maxWidth = 50;
      const maxHeight = 30;
      const aspectRatio = img.width / img.height;

      let finalWidth = maxWidth;
      let finalHeight = maxHeight;

      if (aspectRatio > 1) {
        finalWidth = maxWidth;
        finalHeight = maxWidth / aspectRatio;
        if (finalHeight > maxHeight) {
          finalHeight = maxHeight;
          finalWidth = maxHeight * aspectRatio;
        }
      } else {
        finalHeight = maxHeight;
        finalWidth = maxHeight * aspectRatio;
        if (finalWidth > maxWidth) {
          finalWidth = maxWidth;
          finalHeight = maxWidth / aspectRatio;
        }
      }

      doc.addImage(signatureBase64, 'PNG', 140, finalY, finalWidth, finalHeight);
      doc.setFontSize(12);
      doc.text(`Nombre: ${userName}`, 140, finalY + 35);
      doc.text(`Número de matrícula: ${licenseNumber}`, 140, finalY + 45);
    } else {
      doc.setFontSize(12);
      doc.text(`Nombre: ${userName}`, 10, finalY + 20);
      doc.text(`Número de matrícula: ${licenseNumber}`, 10, finalY + 30);
    }

    const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');
    doc.save(`${form.title} ${creationDate}.pdf`);
  };

  return (
    <div className="form-details">
      <div className="button-container">
        <button data-testid="back-button" onClick={onBack} className="back-button">Volver</button>
        <button data-testid="download-button" onClick={handleDownloadPDF} className="download-button">Descargar</button>
      </div>
      <h2 data-testid="form-title">{form.title}</h2>
      <p data-testid="form-description">{form.description || 'Sin descripción'}</p>

      <table data-testid="form-fields-table" className="form-fields">
        <thead>
          <tr data-testid="form-field-row">
            <th>Campo</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {form.fields.map((field) => (
            <tr key={field._id}>
              <td>{field._doc?.label || field.label || 'Sin etiqueta'}</td>
              <td>{field._doc?.value || field.value || 'Sin valor'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Definición de PropTypes para validar las propiedades recibidas
FormDetails.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.null]),
      })
    ).isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default FormDetails;