import React, { useState, useEffect, useCallback } from 'react'; // Importar useCallback
import Sidebar from '../components/common/Sidebar';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import api from '../api/axiosConfig';
import CustomFormModal from './CustomFormModal.jsx';
import './CustomFormsPage.css';

const CustomFormsPage = () => {
  const [customForms, setCustomForms] = useState([]); // Definir correctamente el estado
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formToEdit, setFormToEdit] = useState(null);
  const [rubros, setRubros] = useState([]); // Rubros disponibles
  const [selectedRubros, setSelectedRubros] = useState([]); // Rubros seleccionados
  const [selectedFormId, setSelectedFormId] = useState(null); // Formulario seleccionado para asignar rubros
  const [showDropdown, setShowDropdown] = useState(false); // Controla la visibilidad del dropdown

  // Función para cargar los formularios personalizados
  const fetchCustomForms = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/custom-forms', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomForms(response.data); // Usar `setCustomForms` en lugar de `setFilteredForms`
      setIsLoading(false);
    } catch (error) {
      console.error('Error al obtener las plantillas de formularios:', error);
      alert('Hubo un problema al obtener las plantillas de formularios. Por favor, inténtelo de nuevo.');
    }
  }, []);

  // Función para cargar los rubros
  const fetchRubros = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/rubros', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRubros(response.data);
    } catch (error) {
      console.error('Error al cargar los rubros:', error);
    }
  };

  // Cargar los formularios y los rubros cuando se monta el componente
  useEffect(() => {
    fetchCustomForms();
    fetchRubros();
  }, [fetchCustomForms]);

  // Esto te ayudará a depurar y asegurarte de que `customForms` se actualice correctamente:
  useEffect(() => {
    console.log('Formularios obtenidos (después de la actualización del estado):', customForms);
  }, [customForms]); // Este useEffect se ejecutará cuando `customForms` se actualice

  // Función para abrir el modal de creación o edición
  const handleOpenModal = (form) => {
    setFormToEdit(form);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setFormToEdit(null);
    setIsModalOpen(false);
  };

  // Función para manejar la selección de rubros
  const handleRubroChange = (rubroId) => {
    if (selectedRubros.includes(rubroId)) {
      setSelectedRubros(selectedRubros.filter((id) => id !== rubroId));
    } else {
      setSelectedRubros([...selectedRubros, rubroId]);
    }
  };

  // Función para asignar rubros al formulario seleccionado
  const handleAssignRubros = async (form) => {
    const selectedRubroNames = rubros
      .filter((rubro) => selectedRubros.includes(rubro._id))
      .map((rubro) => rubro.name)
      .join(', ');

    if (selectedRubros.length === 0) {
      const confirmNoRubros = window.confirm(
        'No has seleccionado ningún rubro, ¿estás seguro que quieres confirmar?'
      );
      if (!confirmNoRubros) return;
    } else {
      const confirmAssign = window.confirm(
        `¿Confirma asignar el formulario "${form.title}" con los rubros: ${selectedRubroNames}?`
      );
      if (!confirmAssign) return;
    }

    try {
      const token = localStorage.getItem('authToken');
      await api.patch(`/api/custom-forms/assign`, {
        formId: form._id,
        rubroIds: selectedRubros,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Rubros asignados exitosamente');
      fetchCustomForms(); // Recargar la lista de formularios
      setShowDropdown(false); // Cierra el dropdown al asignar los rubros
    } catch (error) {
      console.error('Error al asignar los rubros:', error);
      alert('Hubo un problema al asignar los rubros.');
    }
  };

  const handleDeleteForm = async (formId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este formulario? Esta acción no se puede deshacer.');
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.delete(`/api/custom-forms/${formId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Formulario eliminado correctamente.');
      fetchCustomForms();
    } catch (error) {
      console.error('Error al eliminar el formulario:', error);
      alert('Hubo un problema al eliminar el formulario.');
    }
  };

  // Función para abrir el dropdown de rubros con los rubros seleccionados previamente
  const openRubroDropdown = (form) => {
    setSelectedFormId(form._id);

    // Obtiene los IDs de los rubros asignados
    const assignedRubroIds = form.assignedRubros.map((rubro) => rubro._id);

    // Preselecciona los rubros asignados
    setSelectedRubros(assignedRubroIds);

    // Alternar el dropdown
    setShowDropdown((prev) => selectedFormId !== form._id || !prev);
  };

  return (
    <div className="dashboard-container" data-testid="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2>Gestión de Formularios Personalizados</h2>

        <div className="add-form-button">
          <Tooltip title="Agregar Nuevo Formulario">
            <button className="primary-button" onClick={() => handleOpenModal(null)} data-testid="add-form-button">
              <AddIcon /> Agregar Formulario
            </button>
          </Tooltip>
        </div>

        {isLoading ? (
          <p>Cargando formularios...</p>
        ) : (
          <table className='custom-forms-table' data-testid="custom-forms-table">
            <thead>
              <tr>
                <th>Título del Formulario</th>
                <th>Fecha de Creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {customForms.map((form) => (
                <tr key={form._id} className="form-row">
                  <td>{form.title}</td>
                  <td>{new Date(form.createdAt).toLocaleDateString('es-AR')}</td>
                  <td>
                    <div className="dropdown-container" style={{ position: 'relative' }}>
                      <button className="edit-button" onClick={() => handleOpenModal(form)} data-testid={`edit-button-${form._id}`}>
                        Editar
                      </button>
                      <button className="delete-button" onClick={() => handleDeleteForm(form._id)} data-testid={`delete-button-${form._id}`}>
                        Eliminar
                      </button>
                      <button className="primary-button" onClick={() => openRubroDropdown(form)} data-testid={`assign-rubros-button-${form._id}`}>
                        Asignar Rubros
                      </button>

                      {/* Mostrar el dropdown de rubros */}
                      {selectedFormId === form._id && showDropdown && (
                        <div className="dropdown-rubros" data-testid={`dropdown-rubros-${selectedFormId}`}>
                          <label>
                            <input
                              type="checkbox"
                              onChange={() =>
                                setSelectedRubros(
                                  rubros.length === selectedRubros.length
                                    ? []
                                    : rubros.map((rubro) => rubro._id)
                                )
                              }
                              checked={rubros.length === selectedRubros.length}
                              data-testid="select-all-rubros-checkbox"
                            />
                            Seleccionar todos los rubros
                          </label>
                          {rubros.map((rubro) => (
                            <label key={rubro._id}>
                              <input
                                type="checkbox"
                                checked={selectedRubros.includes(rubro._id)}
                                onChange={() => handleRubroChange(rubro._id)}
                                data-testid={`rubro-checkbox-${rubro._id}`}
                              />
                              {rubro.name}
                            </label>
                          ))}
                          <button
                            onClick={() => handleAssignRubros(form)}
                            className="primary-button"
                            data-testid="save-rubros-button"
                          >
                            Guardar Rubros
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Modal para crear o editar un formulario */}
        {isModalOpen && (
          <CustomFormModal
            formToEdit={formToEdit}
            onClose={handleCloseModal}
            refreshForms={fetchCustomForms}
            data-testid="custom-form-modal"
          />
        )}
      </div>
    </div>
  );
};

export default CustomFormsPage;