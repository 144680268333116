import React from 'react';
import PropTypes from 'prop-types';
import viewIcon from '../../assets/show.png';
import editIcon from '../../assets/edit-icon.png';
import deleteIcon from '../../assets/delete-icon.png';
import Tooltip from '@mui/material/Tooltip';
import './FormsList.css';

const FormsList = ({ forms, onView, onEdit, onDelete }) => {

  const handleDeleteClick = (e, form) => {
    e.stopPropagation(); // Detiene la propagación del evento a otros elementos
    onDelete(form);
  };

  return (
    <div className="forms-list-container">
      <table className="forms-table">
        <thead>
          <tr>
            <th>Nombre del Formulario</th>
            <th>Descripción</th>
            <th>Fecha de Creación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {forms.map((form) => (
            <tr data-testid={`form-row-${form._id}`} key={form._id} className="form-row">
              <td>{form.title}</td>
              <td>{form.description || 'Sin descripción'}</td>
              <td>{new Date(form.createdAt).toLocaleDateString()}</td>
              <td>
                <Tooltip title="Ver Reporte">
                  <button data-testid={`view-button-${form._id}`} onClick={(e) => { e.stopPropagation(); onView(form); }}>
                    <img src={viewIcon} alt="Ver" />
                  </button>
                </Tooltip>
                <Tooltip title="Editar Reporte">
                  <button data-testid={`edit-button-${form._id}`} onClick={(e) => { e.stopPropagation(); onEdit(form); }}>
                    <img src={editIcon} alt="Editar" />
                  </button>
                </Tooltip>
                <Tooltip title="Eliminar Reporte">
                  <button data-testid={`delete-button-${form._id}`} onClick={(e) => handleDeleteClick(e, form)}>
                    <img src={deleteIcon} alt="Eliminar" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

FormsList.propTypes = {
  forms: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FormsList;
