// src/screens/ForgotPassword.jsx

import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api/axiosConfig';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/forgot-password', { email });
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      const message = error.response?.data?.message || 'Error al solicitar la recuperación. Inténtalo más tarde.';
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="forgot-password-container" data-testid="forgot-password-container">
      <div className="forgot-password-card" data-testid="forgot-password-card">
        <h2 data-testid="forgot-password-title">Recuperar Contraseña</h2>
        <p>Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
        <form onSubmit={handleSubmit} data-testid="forgot-password-form">
          <label>Correo Electrónico</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Ingresa tu correo"
            data-testid="email-input"
          />
          <button type="submit" data-testid="send-link-button">Enviar Enlace</button>
        </form>
      </div>
      <ToastContainer data-testid="toast-container" />
    </div>
  );
};

export default ForgotPassword;