// components/visits/AttachFormModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/axiosConfig';
import './AttachFormModal.css';

const AttachFormModal = ({ visit, onClose, onFormAttached }) => {
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState('');

  const establishmentId =
  visit.establishmentId._id || visit.establishmentId;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get(
          `/api/establishments/${establishmentId}/checklists`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setForms(response.data);
      } catch (error) {
        console.error('Error al obtener los formularios:', error);
        alert(
          'Hubo un problema al obtener los formularios. Por favor, inténtalo de nuevo.'
        );
      }
    };

    if (establishmentId) {
      fetchForms();
    }
  }, [establishmentId]);

  const handleAttachForm = async () => {
    if (!selectedFormId) {
      alert('Por favor, selecciona un formulario.');
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/visits/${visit._id}/attachForm`, { formId: selectedFormId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Formulario adjuntado correctamente.');
      onFormAttached(); // Actualiza la lista de visitas
      onClose();
    } catch (error) {
      console.error('Error al adjuntar el formulario:', error);
      alert('Hubo un problema al adjuntar el formulario. Por favor, inténtalo de nuevo.');
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h2>Adjuntar Formulario</h2>
        <label>
          Selecciona un formulario:
          <select data-testid="form-select" value={selectedFormId} onChange={(e) => setSelectedFormId(e.target.value)}>
            <option value="">Selecciona un formulario</option>
            {forms.map((form) => (
              <option key={form._id} value={form._id}>
                {form.title} - {new Date(form.createdAt).toLocaleDateString()}
              </option>
            ))}
          </select>
        </label>
        <div className="modal-buttons">
          <button data-testid="attach-form-button" onClick={handleAttachForm}>Adjuntar</button>
          <button data-testid="cancel-attach-button" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

AttachFormModal.propTypes = {
  visit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormAttached: PropTypes.func.isRequired,
};

export default AttachFormModal;