import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import api from '../../api/axiosConfig';
import WorkingHoursDisplay from '../calendar/WorkingHoursDisplay';
import './EstablishmentWorkingHours.css';

const initialDays = [
  { day: 'Lunes', intervals: [], enabled: false },
  { day: 'Martes', intervals: [], enabled: false },
  { day: 'Miércoles', intervals: [], enabled: false },
  { day: 'Jueves', intervals: [], enabled: false },
  { day: 'Viernes', intervals: [], enabled: false },
  { day: 'Sábado', intervals: [], enabled: false },
  { day: 'Domingo', intervals: [], enabled: false },
];

const EstablishmentWorkingHours = ({ establishmentId }) => {
  const [open, setOpen] = useState(false);
  const [workingHours, setWorkingHours] = useState(initialDays);

  const handleTimeChange = (dayIndex, slotIndex, type, value) => {
    const updatedHours = [...workingHours];
    updatedHours[dayIndex].intervals[slotIndex][type] = value;
    setWorkingHours(updatedHours);
  };

  const addTimeSlot = (dayIndex) => {
    const updatedHours = [...workingHours];
    updatedHours[dayIndex].intervals.push({ start: '09:00', end: '17:00' });
    setWorkingHours(updatedHours);
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    const updatedHours = [...workingHours];
    updatedHours[dayIndex].intervals.splice(slotIndex, 1);
    setWorkingHours(updatedHours);
  };

  useEffect(() => {
    if (!establishmentId) return;

    const fetchWorkingHours = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get(`/api/establishments/${establishmentId}/working-hours`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedHours = response.data.workingHours;

        const updatedHours = initialDays.map(day => {
          const fetchedDay = fetchedHours.find(fh => fh.day === day.day);
          return fetchedDay
            ? { ...day, intervals: fetchedDay.intervals || [], enabled: fetchedDay.intervals.length > 0 }
            : day;
        });

        setWorkingHours(updatedHours);
      } catch (error) {
        console.error('Error al obtener los horarios:', error);
        alert('Hubo un problema al cargar los horarios de atención.');
      }
    };

    fetchWorkingHours();
  }, [establishmentId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleDay = (index) => {
    const updatedHours = [...workingHours];
    const day = updatedHours[index];

    if (!day.enabled) {
      day.enabled = true;
      if (day.intervals.length === 0) {
        day.intervals.push({ start: '09:00', end: '17:00' });
      }
    } else {
      day.enabled = false;
      day.intervals = [];
    }

    setWorkingHours(updatedHours);
  };

  const handleSave = async () => {
    const invalidDay = workingHours.find(day => day.enabled && day.intervals.length === 0);

    if (invalidDay) {
      alert(`El día ${invalidDay.day} está habilitado pero no tiene horarios. Agrega al menos una franja horaria.`);
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const formattedWorkingHours = workingHours.map(day => ({
        day: day.day,
        intervals: day.enabled ? day.intervals : [],
      }));

      await api.put(`/api/establishments/${establishmentId}/working-hours`, { workingHours: formattedWorkingHours }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Horarios de atención guardados exitosamente');
      handleClose();
    } catch (error) {
      console.error('Error al guardar los horarios:', error);
      alert('Hubo un problema al guardar los horarios.');
    }
  };

  return (
    <div className='working-hours-container'>
      <button data-testid="edit-working-hours-button" onClick={handleOpen} className="edit-working-hours-button">
        Editar Horarios de Atención
      </button>
      <WorkingHoursDisplay workingHours={workingHours} />

      <Modal open={open} onClose={handleClose} className="working-hours-modal" data-testid="working-hours-modal">
        <div className="modal-content">
          <h2>Configura los Horarios de Atención</h2>
          {workingHours.map((day, dayIndex) => (
            <div key={day.day} className="day-row">
              <Switch
                data-testid={`toggle-day-${day.day}`}
                checked={day.enabled}
                onChange={() => toggleDay(dayIndex)}
                color="primary"
              />
              <span>{day.day}</span>
              {day.enabled && (
                <div className="time-slots">
                  {day.intervals.map((interval, intervalIndex) => (
                    <div key={intervalIndex} className="time-slot">
                      <input
                        data-testid={`start-time-${day.day}-${intervalIndex}`}
                        type="time"
                        value={interval.start}
                        step="1800"
                        onChange={(e) =>
                          handleTimeChange(dayIndex, intervalIndex, 'start', e.target.value)
                        }
                      />
                      <span>a</span>
                      <input
                        data-testid={`end-time-${day.day}-${intervalIndex}`}
                        type="time"
                        value={interval.end}
                        step="1800"
                        onChange={(e) =>
                          handleTimeChange(dayIndex, intervalIndex, 'end', e.target.value)
                        }
                      />
                      <button data-testid={`remove-time-slot-${day.day}-${intervalIndex}`} onClick={() => removeTimeSlot(dayIndex, intervalIndex)}>
                        🗑️
                      </button>
                    </div>
                  ))}
                  <button data-testid={`add-time-slot-${day.day}`} onClick={() => addTimeSlot(dayIndex)}>Agregar Horario</button></div>
              )}
            </div>
          ))}
          <div className="modal-actions">
            <button data-testid="save-working-hours" onClick={handleSave} className="save-button">
              Guardar
            </button>
            <button data-testid="cancel-working-hours" onClick={handleClose} className="cancel-button">
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

EstablishmentWorkingHours.propTypes = {
  establishmentId: PropTypes.string.isRequired,
};

export default EstablishmentWorkingHours;