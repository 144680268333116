import React, { useState, useEffect, useRef } from 'react';
import './Profile.css';
import profilePlaceholder from '../assets/profile-icon.png';
import Sidebar from '../components/common/Sidebar';
import api from '../api/axiosConfig';
import defaultSignature from '../assets/default_signature.png';
import defaultCompanyLogo from '../assets/default_company_logo.png';
import Tooltip from '@mui/material/Tooltip'; // Importa el componente Tooltip
import infoIcon from '../assets/info.png'; // Importa tu imagen local

const Profile = () => {
    const [name, setName] = useState('N/A');
    const [surname, setSurname] = useState('N/A');
    const [licenseNumber, setLicenseNumber] = useState('N/A');
    const [previewProfilePic, setPreviewProfilePic] = useState(profilePlaceholder);
    const [previewSignatureImage, setPreviewSignatureImage] = useState(defaultSignature);
    const [previewCompanyLogo, setPreviewCompanyLogo] = useState(defaultCompanyLogo);
    const [editing, setEditing] = useState(false);
    const [nameError, setNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    const fileInputRef = useRef(null);
    const signatureInputRef = useRef(null);
    const companyLogoInputRef = useRef(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await api.get('/api/users/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const userData = response.data;
                setName(userData.name || 'Nombre');
                setSurname(userData.surname || 'Apellido');
                setLicenseNumber(userData.licenseNumber || 'N/A');

                if (userData.profilePic && userData.profilePic !== '') {
                    setPreviewProfilePic(userData.profilePic);
                } else {
                    setPreviewProfilePic(profilePlaceholder);
                }

                if (userData.signatureImage && userData.signatureImage !== '') {
                    setPreviewSignatureImage(userData.signatureImage);
                } else {
                    setPreviewSignatureImage(defaultSignature);
                }

                if (userData.companyLogo && userData.companyLogo !== '') {
                    setPreviewCompanyLogo(userData.companyLogo);
                } else {
                    setPreviewCompanyLogo(defaultCompanyLogo);
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleEditToggle = () => {
        setEditing(!editing);
    };

    const handleProfilePicClick = () => {
        fileInputRef.current.click();
    };

    const handleSignatureClick = () => {
        signatureInputRef.current.click();
    };

    const handleCompanyLogoClick = () => {
        companyLogoInputRef.current.click();
    };

    const handleSignatureChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 5000000) {
            if (validImageTypes.includes(file.type)) { // Check if the file type is valid
                try {
                    const token = localStorage.getItem('authToken');
                    const formData = new FormData();
                    formData.append('signatureImage', file);

                    const response = await api.put('/api/users/profile/signature', formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    const { signatureImage } = response.data;
                    setPreviewSignatureImage(signatureImage);
                } catch (error) {
                    console.error('Error updating signature image:', error);
                }
            } else {
                alert('Por favor, selecciona un archivo de imagen válido (PNG, JPG, JPEG).');
            }
        } else {
            alert('El tamaño máximo permitido es 5MB.');
        }
    };

    const handleCompanyLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 5000000) {
            if (validImageTypes.includes(file.type)) { // Check if the file type is valid
                try {
                    const token = localStorage.getItem('authToken');
                    const formData = new FormData();
                    formData.append('companyLogo', file);

                    const response = await api.put('/api/users/profile/logo', formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    const { companyLogo } = response.data;
                    setPreviewCompanyLogo(companyLogo);
                } catch (error) {
                    console.error('Error updating company logo:', error);
                }
            } else {
                alert('Por favor, selecciona un archivo de imagen válido (PNG, JPG, JPEG).');
            }
        } else {
            alert('El tamaño máximo permitido es 5MB.');
        }
    };

    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 5000000) {
            if (validImageTypes.includes(file.type)) { // Check if the file type is valid
                try {
                    const token = localStorage.getItem('authToken');
                    const formData = new FormData();
                    formData.append('profilePic', file);

                    const response = await api.put('/api/users/profile/photo', formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    const { profilePic } = response.data;
                    setPreviewProfilePic(profilePic);
                } catch (error) {
                    console.error('Error updating profile picture:', error);
                }
            } else {
                alert('Por favor, selecciona un archivo de imagen válido (PNG, JPG, JPEG).');
            }
        } else {
            alert('El tamaño máximo permitido es 5MB.');
        }
    };

    const handleSave = async () => {
        // Limpiar los errores antes de validar
        setNameError('');
        setSurnameError('');

        // Validar que el nombre y apellido no estén vacíos
        if (!name || name.trim() === '') {
            setNameError('El nombre es obligatorio.');
        }
        if (!surname || surname.trim() === '') {
            setSurnameError('El apellido es obligatorio.');
        }

        // Si hay errores, no proceder con el guardado
        if (!name || name.trim() === '' || !surname || surname.trim() === '') {
            return;
        }

        try {
            const token = localStorage.getItem('authToken');
            await api.put(
                '/api/users/profile',
                {
                    name: name || 'Nombre',
                    surname: surname || 'Apellido',
                    licenseNumber: licenseNumber !== '' ? licenseNumber : '-', // Si la matrícula está vacía, envía "-"
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleDeleteSignature = async () => {
        const confirmed = window.confirm('¿Estás seguro de que deseas eliminar la firma?');
        if (!confirmed) return; // If the user cancels, exit the function

        try {
            const token = localStorage.getItem('authToken');
            await api.delete('/api/users/profile/signature', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreviewSignatureImage(defaultSignature);
        } catch (error) {
            console.error('Error deleting signature image:', error);
        }
    };

    const handleDeleteCompanyLogo = async () => {
        const confirmed = window.confirm('¿Estás seguro de que deseas eliminar el logo de la empresa?');
        if (!confirmed) return; // If the user cancels, exit the function

        try {
            const token = localStorage.getItem('authToken');
            await api.delete('/api/users/profile/logo', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreviewCompanyLogo(defaultCompanyLogo);
        } catch (error) {
            console.error('Error deleting company logo:', error);
        }
    };

    const handleDeleteProfilePic = async () => {
        const confirmed = window.confirm('¿Estás seguro de que deseas eliminar la imagen de perfil?');
        if (!confirmed) return; // If the user cancels, exit the function

        try {
            const token = localStorage.getItem('authToken');
            await api.delete('/api/users/profile/photo', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreviewProfilePic(profilePlaceholder);
        } catch (error) {
            console.error('Error deleting profile picture:', error);
        }
    };

    return (
        <div className="profile-page-container" data-testid="profile-page-container">
            <Sidebar />

            <div className="profile-sidebar">
                <h2 className="section-title">Perfil</h2>
                <div className="profile-picture-container">
                    <img src={previewProfilePic} alt="Profile" className="profile-picture" data-testid="profile-picture" />
                    <div className="profile-picture-overlay" onClick={handleProfilePicClick}>
                        Cambiar imagen de perfil
                    </div>
                </div>
                <Tooltip title="Borrar imagen de perfil" arrow>
                    <button onClick={handleDeleteProfilePic} data-testid="delete-profile-picture">Eliminar</button>
                </Tooltip>

                <h2>
                    {name !== 'N/A' ? name : 'Nombre'} {surname !== 'N/A' ? surname : 'Apellido'}
                </h2>
                <p>Número de matrícula: {licenseNumber !== 'N/A' ? licenseNumber : 'Vacío'}</p>
            </div>

            <div className="profile-details">
                <h2 className="section-title">
                    Detalles
                    <Tooltip title="Esta información se mostrará al final de los formularios en formato PDF." arrow>
                        <img src={infoIcon} alt="Info" style={{ marginLeft: 10, width: 25, height: 25, cursor: 'pointer' }} />
                    </Tooltip>
                </h2>
                {editing ? (
                    <form className="profile-form">
                        <div className="forms">
                            <label>Nombre</label>
                            <input
                                type="text"
                                value={name !== 'N/A' ? name : ''}
                                onChange={(e) => setName(e.target.value)}
                                data-testid="name-input"
                            />
                            {nameError && <span className="error-message">{nameError}</span>}
                        </div>
                        <div className="forms">
                            <label>Apellido</label>
                            <input
                                type="text"
                                value={surname !== 'N/A' ? surname : ''}
                                onChange={(e) => setSurname(e.target.value)}
                                data-testid="surname-input"
                            />
                            {surnameError && <span className="error-message">{surnameError}</span>}
                        </div>
                        <div className="forms">
                            <label>Número de Matrícula</label>
                            <Tooltip
                                title="Si dejas este campo vacío, el número de matrícula no se mostrará en el PDF generado."
                                open={licenseNumber === '' || licenseNumber === 'N/A'}
                                arrow
                            >
                                <input
                                    type="text"
                                    value={licenseNumber !== 'N/A' ? licenseNumber : ''}
                                    onChange={(e) => setLicenseNumber(e.target.value)}
                                    data-testid="license-number-input"
                                />
                            </Tooltip>
                        </div>
                        <button type="button" onClick={handleSave} data-testid="save-profile-button">
                            Guardar
                        </button>
                    </form>
                ) : (
                    <div className="profile-info">
                        <p>
                            <strong>Nombre:</strong> <br />
                            {name !== 'N/A' ? name : 'Vacío'}
                        </p>
                        <p>
                            <strong>Apellido:</strong> <br />
                            {surname !== 'N/A' ? surname : 'Vacío'}
                        </p>
                        <p>
                            <strong>Número de Matrícula:</strong>
                            <br />
                            {licenseNumber !== 'N/A' ? licenseNumber : 'Vacío'}
                        </p>
                        <Tooltip title="Editar datos personales" arrow>
                            <button onClick={handleEditToggle} data-testid="edit-profile-button">Editar</button>
                        </Tooltip>
                    </div>
                )}
            </div>

            <div className="profile-details">
                <h2 className="section-title">
                    Foto de firma
                    <Tooltip title="Esta firma se mostrará al final de los formularios en formato PDF." arrow>
                        <img src={infoIcon} alt="Info" style={{ marginLeft: 10, width: 25, height: 25, cursor: 'pointer' }} />
                    </Tooltip>
                </h2>
                <div className="signature-section">
                    <div className="signature-container">
                        <img src={previewSignatureImage} alt="Firma" className="signature-image" data-testid="signature-image" />                        <div className="signature-overlay" onClick={handleSignatureClick}>
                            Actualizar Firma
                        </div>
                    </div>
                </div>
                <Tooltip title="Borrar firma de usuario" arrow>
                    <button onClick={handleDeleteSignature} data-testid="delete-signature">Eliminar</button>
                </Tooltip>
            </div>

            <div className="profile-details">
                <h2 className="section-title">
                    Logo
                    <Tooltip title="Este logo se mostrará arriba a la izquierda de los formularios en formato PDF." arrow>
                        <img src={infoIcon} alt="Info" style={{ marginLeft: 10, width: 25, height: 25, cursor: 'pointer', }} />
                    </Tooltip>
                </h2>
                <div className="company-logo-section">
                    <div className="company-logo-container">
                        <img src={previewCompanyLogo} alt="Logo de la Empresa" className="company-logo-image" data-testid="company-logo-image" />
                        <div className="company-logo-overlay" onClick={handleCompanyLogoClick}>
                            Actualizar Logo
                        </div>
                    </div>
                </div>
                <Tooltip title="Borrar logo de empresa / marca" arrow>
                    <button onClick={handleDeleteCompanyLogo} data-testid="delete-company-logo">Eliminar</button>
                </Tooltip>
            </div>

            <input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                ref={fileInputRef}
                data-testid="upload-profile-picture-input"
                style={{ display: 'none' }}
            />
            <input
                type="file"
                accept="image/*"
                onChange={handleSignatureChange}
                ref={signatureInputRef}
                data-testid="upload-signature-input"
                style={{ display: 'none' }}
            />
            <input
                type="file"
                accept="image/*"
                onChange={handleCompanyLogoChange}
                ref={companyLogoInputRef}
                data-testid="upload-company-logo-input"
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default Profile;