import React from 'react';
import './EstablishmentsList.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import editIcon from '../../assets/edit-icon.png';
import deleteIcon from '../../assets/delete-icon.png';

// Iconos para flechas
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const EstablishmentsList = ({ establishments, onEdit, onDelete, onSort, sortField, sortDirection }) => {
  const navigate = useNavigate();

  // Función para manejar el clic en una fila
  const handleRowClick = (event, establishmentId) => {
    if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'IMG') {
      navigate(`/dashboard/establecimiento/${establishmentId}/details`);
    }
  };

  // Función para renderizar el icono de ordenación
  const renderSortIcon = (field) => {
    if (sortField !== field) {
      return <FaSort />;
    }
    return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="establishments-list-container">
      {establishments.length === 0 ? (
        <p data-testid="no-establishments-message">No hay establecimientos disponibles.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th data-testid="header-name" onClick={() => onSort('name')}>
                Nombre {renderSortIcon('name')}
              </th>
              <th>Dirección</th>
              <th data-testid="header-city" onClick={() => onSort('city')}>
                Localidad {renderSortIcon('city')}
              </th>
              <th data-testid="header-province" onClick={() => onSort('province')}>
                Provincia {renderSortIcon('province')}
              </th>
              <th data-testid="header-industry" onClick={() => onSort('industry')}>
                Rubro {renderSortIcon('industry')}
              </th>
              <th>Información de Contacto</th>
              <th data-testid="header-created-at" onClick={() => onSort('createdAt')}>
                Fecha de Creación {renderSortIcon('createdAt')}
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {establishments.map((establishment) => (
              <tr key={establishment._id} onClick={(event) => handleRowClick(event, establishment._id)}>
                <td data-testid={`establishment-name-${establishment._id}`}>{establishment.name}</td>
                <td data-testid={`establishment-address-${establishment._id}`}>{establishment.address}</td>
                <td data-testid={`establishment-city-${establishment._id}`}>{establishment.city}</td>
                <td data-testid={`establishment-province-${establishment._id}`}>{establishment.province}</td>
                <td data-testid={`establishment-industry-${establishment._id}`}>{establishment.industry ? establishment.industry.name : 'Sin rubro'}</td>
                <td data-testid={`establishment-contact-${establishment._id}`}>{establishment.contactInfo}</td>
                <td data-testid={`establishment-created-at-${establishment._id}`}>{new Date(establishment.createdAt).toLocaleDateString()}</td>
                <td>
                  <Tooltip title="Editar">
                    <button data-testid={`edit-button-${establishment._id}`} onClick={() => onEdit(establishment)}>
                      <img src={editIcon} alt="Editar" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <button data-testid={`delete-button-${establishment._id}`} onClick={() => onDelete(establishment)}>                      <img src={deleteIcon} alt="Eliminar" />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

EstablishmentsList.propTypes = {
  establishments: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,  // Nueva función para ordenación
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

export default EstablishmentsList;