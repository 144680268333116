// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/common/Header';
import ProfilePage from './screen/Profile';
import Login from './screen/Login';
import Signup from './screen/Signup';
import Dashboard from './screen/Dashboard';
import Establishments from './screen/Establishments';
import Establishment from './screen/Establishment';
import Settings from './screen/Settings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import CustomForms from './screen/CustomFormsPage';
import VerifyEmail from './screen/VerifyEmail';
import ForgotPassword from './screen/ForgotPassword';
import ResetPassword from './screen/ResetPassword';
import SuggestedRoutes from './screen/SuggestedRoutes';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// Validación de PropTypes para children
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppContent = () => {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('authToken'); // Verificar autenticación

  // Condicionar el renderizado del Header según la página
  const showHeader =
    location.pathname !== '/login' &&
    location.pathname !== '/signup' &&
    location.pathname !== '/verify-email' &&
    location.pathname !== '/forgot-password' &&
    !location.pathname.includes('/reset-password');

  return (
    <div className="App">
      {showHeader && <Header />}

      {/* Contenedor de notificaciones de React-Toastify */}
      <ToastContainer
        position="top-right"
        style={{ zIndex: 9999, marginTop: showHeader ? '60px' : '0px' }} // Ajusta '60px' según la altura de tu encabezado
      />

      <Routes>
        {/* Redirigir automáticamente desde la raíz */}
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/establecimientos"
          element={
            <PrivateRoute>
              <Establishments />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/establecimiento/:id/*"
          element={
            <PrivateRoute>
              <Establishment />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        {/* Nueva ruta para la página de formularios personalizados */}
        <Route
          path="/dashboard/custom-forms"
          element={
            <PrivateRoute>
              <CustomForms />
            </PrivateRoute>
          }
        />
        {/* Nueva ruta para la página de rutas sugeridas */}
        <Route
          path="/dashboard/suggested-routes"
          element={
            <PrivateRoute>
              <SuggestedRoutes />
            </PrivateRoute>
          }
        />
        {/* Nueva ruta para la verificación de correo */}
        <Route path="/verify-email" element={<VerifyEmail />} />
        {/* Nueva ruta para la recuperación de contraseña */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Nueva ruta para restablecer la contraseña */}
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;