import React from 'react';
import PropTypes from 'prop-types';
import './WorkingHoursDisplay.css';

const WorkingHoursDisplay = ({ workingHours }) => {
  return (
    <div className="working-hours-container" data-testid="working-hours-container">
      <h2 data-testid="working-hours-title">Horarios de Atención</h2>
      <div className="days-container">
        {workingHours.map((day) => (
          <div key={day.day} className="day-row" data-testid={`day-row-${day.day}`}>
            <span className="day-name" data-testid={`day-name-${day.day}`}>{day.day}</span>
            {day.intervals && day.intervals.length > 0 ? (
              <span className="hours">
                {day.intervals.map((interval, index) => (
                  <span key={index} data-testid={`hours-interval-${day.day}-${index}`}>
                    {interval.start} - {interval.end}
                    {index < day.intervals.length - 1 && ', '}
                  </span>
                ))}
              </span>
            ) : (
              <span className="closed" data-testid={`closed-${day.day}`}>Cerrado</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

WorkingHoursDisplay.propTypes = {
  workingHours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      intervals: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default WorkingHoursDisplay;